import React from 'react';
import './App.css';
import {Box, Button, Container, Flex, Heading, Icon, Image, Stack, Text} from "@chakra-ui/react";
import Card from "./Components/Card";
import {FaArchive, FaFolder, FaGithub} from "react-icons/fa";
import {AiOutlineProject} from "react-icons/ai";
import {IoDocuments} from "react-icons/io5";

function App() {
  return (
  <>


      <Container minWidth={'100vw'} minHeight={"97vh"} bgColor={"#041C32"} flexDirection={"column"}>
        <Box p={4} >
          <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
            <Image src={"img/logo.png"} alt={"logo"} mb={-120} mt={-140} maxW={"350px"} overflow={"hidden"} alignSelf={'center'} pos={"relative"}/>

            <Heading color={"#F7941D"} fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
              Unfinished Systems
            </Heading>

            <Text color={'gray.400'} fontSize={{ base: 'sm', sm: 'lg' }}>
              A series of unfinished projects, documentation and code snippets.
            </Text>
          </Stack>

          <Container maxW={'10xl'} mt={12} >
            <Flex flexWrap="wrap" gridGap={6} justify="center">
              <Card
                  heading={'Documentation'}
                  icon={<Icon as={IoDocuments} mr={2} w={5} h={5} />}
                  description={'This is my documentation, where I keep all my notes and thoughts.'}
                  href={'#'}
                  image={"https://images.unsplash.com/photo-1521791055366-0d553872125f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8RG9jdW1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"}
              />
              <Card
                  heading={'Code Examples'}
                  icon={<Icon as={FaGithub} mr={2} w={5} h={5} />}
                  description={'This is where you can find all my code examples and snippets.'}
                  href={'#'}
                  image={"https://images.unsplash.com/photo-1594904351111-a072f80b1a71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Q29kZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"}
              />
              <Card
                  heading={'Current Projects'}
                  icon={<Icon as={AiOutlineProject} mr={2} w={5} h={5} />}
                  description={'All of my current projects that I am working on.'}
                  href={'#'}
                  image={"https://images.unsplash.com/photo-1571666521805-f5e8423aba9d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fFByb2plY3R8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"}
              />
              <Card
                  heading={'Archived Projects'}
                  icon={<Icon as={FaArchive} mr={2} w={5} h={5} />}
                  description={'All of the projects that I have archived.'}
                  href={'#'}
                  image={"https://images.unsplash.com/photo-1572177812156-58036aae439c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"}
              />
              <Card
                  heading={'File Browser'}
                  icon={<Icon as={FaFolder} mr={2} w={5} h={5} />}
                  description={'A file browser to download files from my server.'}
                  href={'#'}
                  image={"https://images.unsplash.com/photo-1569235186275-626cb53b83ce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmlsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"}
              />
            </Flex>
          </Container>
        </Box>
      </Container>

      <Text textAlign={"center"} style={{position: "relative", width: "100%", marginTop: "auto", marginBottom:0, backgroundColor: "#041C32", padding: 4, color: "#F7941D", fontSize: "small"}}>
        Another unfinished project from Unfinished Systems | Images from <a href={"https://unsplash.com/"} style={{color: "#64CCC5"}}>Unsplash</a> | &copy; 2023 Unfinished Systems
      </Text>
  </>
);
}

export default App;
