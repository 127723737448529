import React from 'react';
import {CardProps} from "../Utilities/Types";
import {Avatar, Box, Button, Image, Heading, Stack, Text, useColorModeValue, Flex} from "@chakra-ui/react";

function Card({ heading, description, icon, href, image }: CardProps){
  return (
      <Box
          maxW={'300px'}
          w={'full'}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}
          overflow={'hidden'}>
        <Box overflow={'hidden'} maxH={'200px'} maxW={'300px'} bg={'gray.100'} mt={-6} mx={-6} mb={6} pos={'relative'}>
          <Image
              src={image}
              alt="Example"
          />
        </Box>
        <Stack mb={5}>
          <Heading
              // eslint-disable-next-line react-hooks/rules-of-hooks
              color={useColorModeValue('gray.700', 'white')}
              fontSize={'2xl'}
              fontFamily={'body'}>
            {icon}
            {heading}
          </Heading>
          <Text color={'gray.500'}>
            {description}
          </Text>


        </Stack>
        <Stack>
          <Button color={"#050708"} backgroundColor={"#F7941D"}>
            Coming Soon
          </Button>
        </Stack>
      </Box>
  )
}

export default Card;